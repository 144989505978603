import Swiper from 'swiper';

//スライダー周りの処理
let sliders = []
//不動産詳細
if (document.querySelector('body').classList.contains('page-realestate')) {
  let galleryThumbs = new Swiper('.gallery-thumbs', {
    autoplay: false,
    // loop: true,
    autoScrollOffset: 1,
    slidesPerView: 'auto',
    watchSlidesVisibility: true,
    breakpoints: {
      768: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    },
  });
  let galleryTop = new Swiper('.gallery-top', {
    autoplay: false,
    // loop: true,
    lazy: true,
    spaceBetween: 10,
    thumbs: {
      swiper: galleryThumbs,
      autoScrollOffset: 1
    },
    breakpoints: {
      768: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    },
  });
}

//モデルハウス
if (document.querySelector('body').classList.contains('page-showroom')) {
  document.querySelectorAll('.gallery-thumbs').forEach( el => {
    let galleryThumbs = new Swiper(el, {
      slidesPerView: 'auto',
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      freeMode: true,
    });
    const target = document.querySelector('.gallery-top[name=' + el.getAttribute('data-target') + ']');
    let galleryOptions = {
      lazy: true,
      speed: 300,
    }
    if (target) galleryOptions.thumbs = {
      swiper: galleryThumbs,
    };
    var gallery = new Swiper(target, galleryOptions);
    sliders.push(gallery);
    return gallery;
  })
  
}

//施工事例
if (document.querySelector('body').classList.contains('page-results-detail')) {
  let galleryThumbs = new Swiper('.gallery-thumbs', {
    slidesPerView: 'auto',
    spaceBetween: 10,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    breakpoints: {
      768: {
        spaceBetween: 20,
        // slidesPerView: 'auto',
      },
    },
  });
  let galleryTop = new Swiper('.gallery-top', {
    spaceBetween: 10,
    lazy: true,
    thumbs: {
      swiper: galleryThumbs,
    },
  });
}

//モデルハウス
if (document.querySelector('body').classList.contains('page-vr')) {
  document.querySelectorAll('.gallery-thumbs').forEach( el => {
    let galleryThumbs = new Swiper(el, {
      slidesPerView: 'auto',
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      freeMode: true,
      breakpoints: {
        768: {
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        },
      },
    });
    const target = document.querySelector('.gallery-top[name=' + el.getAttribute('data-target') + ']');
    let galleryOptions = {
      lazy: true,
      speed: 300,
    }
    if (target) galleryOptions.thumbs = {
      swiper: galleryThumbs,
    };
    var gallery = new Swiper(target, galleryOptions);
    sliders.push(gallery);
    return gallery;
  })
  
}

export default sliders;