import picturefill from "picturefill";
import utils from "./components/utils.js"
import top from "./components/top.js"
import commonUi from "./components/common-ui.js"
import slider from "./components/slider.js"
import contact from "./components/contact.js"
import casestudy from "./components/casestudy.js"
import research from "./components/research.js"
import realestate from "./components/realestate.js"
import topics from "./components/topics.js"
import about from "./components/about.js"
import blog from "./components/blog.js"
import faq from "./components/faq.js"
// import gallery from "./components/gallery.js"
import "./components/form.js"

picturefill();
