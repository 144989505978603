import Pagination from './pagination.js';
(function (d) {
    if (!location.pathname.match(/^\/casestudy\//)) {
        return;
    }

    const target = d.querySelector('.article-list');
    const paginationElement = d.querySelector('.pagination')
    if (target && paginationElement) {
        const pagination = new Pagination({
            target: target,
            body: paginationElement,
            prev: paginationElement.querySelector(".prev"),
            next: paginationElement.querySelector(".next"),
            shortenAfter: paginationElement.querySelector(".shorten"),
            shortenBefore: paginationElement.querySelector(".shorten").cloneNode(true),
            hash: '',
            itemPerPage: 12
        })
    }
    // d.querySelectorAll('.tab-nav li a').forEach(function (tab) {
    //     const href = tab.getAttribute('href')
    //     const tabContent = d.querySelector(href);
    //     const target = tabContent.querySelector('.article-list')
    //     const paginationElement = tabContent.querySelector('.pagination')
    //     if (target && paginationElement) {
    //         const pagination = new Pagination({
    //             target: target,
    //             body: paginationElement,
    //             prev: paginationElement.querySelector(".prev"),
    //             next: paginationElement.querySelector(".next"),
    //             shortenAfter: paginationElement.querySelector(".shorten"),
    //             shortenBefore: paginationElement.querySelector(".shorten").cloneNode(true),
    //             hash: href
    //         })
    //         tab.addEventListener('click', e => {
    //             pagination.setPage(1);
    //         })
    //     }
    // })


})(document);