const d = document.body;

function Pagination(options) {
  const optionDefault = {
    target: document.querySelector(".itemList"),
    body: document.querySelector('.pagination'),
    itemPerPage: 12,
    hash: ''
  }
  if (!options) {
    options = Object.assign({}, optionDefault);
  } else {
    //target
    this.target = options.target ? options.target : optionDefault.target;
    //body
    this.body = options.body ? options.body : optionDefault.body;
    //itemPerPage
    this.itemPerPage = options.itemPerPage ? options.itemPerPage : optionDefault.itemPerPage;
    //hash
    this.hash = options.hash ? options.hash : optionDefault.hash;
    //prev 
    this.prev = options.prev ? options.prev : body.querySelector(".prev");
    //next
    this.next = options.next ? options.next : body.querySelector(".next");
    //shortenAfter
    this.shortenAfter = options.shortenAfter ? options.shortenAfter : body.querySelector(".shorten");
    //shortenBefore
    this.shortenBefore = options.shortenBefore ? options.shortenBefore : body.querySelector(".shorten").cloneNode(true);
  };

  this.setPage = function(page ,setUrl = true) {
    const listSize = this.target.children.length
    const hash = this.hash
    const ITEM_PER_PAGE = this.itemPerPage
    const pageMax = Math.ceil(listSize / ITEM_PER_PAGE);
    const pageNum = Math.max(Math.min(page, pageMax), 1);

    // コンテンツdisplay変更
    for (let index = 0; index < this.target.children.length; index++) {
      const item = this.target.children[index];
      const no = index + 1;
      const onPage = Math.ceil(no / ITEM_PER_PAGE) === pageNum;
      item.style.display = onPage ? "" : "none";
    }
    // Array.from(this.target.children).forEach(function (item, index) {
      // const imageSrc = item.dataset.image;
      // if(imageSrc) item.querySelector("img.itemImage").src = imageSrc;
    // });

    //ページャー変更
    this.body.innerHTML = "";
    if (listSize == 0) return;

    const createPageButton = function (no) {
      const e = document.createElement("li");
      e.innerHTML = `<a href="?page=${no}${hash}">${no}</a>`;
      if (no === pageNum) {
        e.className = "active";
      }
      return e;
    }

    const CLUSTER_MARGIN = 1;
    const clusterStart = Math.max(pageNum - CLUSTER_MARGIN, 1);
    const clusterEnd = Math.min(pageNum + CLUSTER_MARGIN, pageMax);

    if (pageNum !== 1) {
      this.body.appendChild(this.prev);
      this.prev.querySelector("a").href = "?page=" + (pageNum - 1)  + hash;
    }

    if (clusterStart > 1) {
      this.body.appendChild(createPageButton(1));
    }

    if (clusterStart > 2) {
      this.body.appendChild(this.shortenBefore);
    }

    for (let n = clusterStart; n <= clusterEnd; n++) {
      this.body.appendChild(createPageButton(n));
    }

    if (clusterEnd <= pageMax - 2) {
      this.body.appendChild(this.shortenAfter);
    }

    if (clusterEnd <= pageMax - 1) {
      this.body.appendChild(createPageButton(pageMax));
    }

    if (pageNum !== pageMax) {
      this.body.appendChild(this.next);
      this.next.querySelector("a").href = "?page=" + (pageNum + 1) + hash;
    }
    if (setUrl){
      const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?page=' + pageNum + hash;
      window.history.pushState({
        path: newUrl
      }, '', newUrl);
    }
  }

  //HTTPパラメーターからページ取得
  const matchSearch = location.search.match(/[?&]page=(\d+)/);
  const page = matchSearch ? matchSearch[1] : 1;
  this.setPage(page ,false);
}
export default Pagination;