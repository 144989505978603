import Pagination from './pagination.js';
import ValidateForm from './form.js';
(function (d) {
  if (!location.pathname.match(/^\/topics\//)) {
    return;
  }

  const target = d.querySelector('.article-list');
  const paginationElement = d.querySelector('.pagination')
  if (target && paginationElement) {
    const pagination = new Pagination({
      target: target,
      body: paginationElement,
      prev: paginationElement.querySelector(".prev"),
      next: paginationElement.querySelector(".next"),
      shortenAfter: paginationElement.querySelector(".shorten"),
      shortenBefore: paginationElement.querySelector(".shorten").cloneNode(true),
      hash: '',
      itemPerPage: 12
    })
  }
  const customTimes = d.querySelector('#custome-times');
  if(customTimes && customTimes.value) {
    const times = customTimes.value.split(/\n/);
    const select = d.querySelector('#form-preferred_times');
    select.innerHTML = '<option value="">お選びください</option>';
    times.forEach(time => {
      const option = d.createElement('option');
      option.value = time
      option.innerHTML = time
      select.appendChild(option)
    });
  }

})(document);