import Pagination from './pagination.js';
(function (d) {
  if (!location.pathname.match(/^\/realestate\//)) {
    return;
  }

  if (location.pathname.match(/^(\/realestate\/tateuri\/|\/realestate\/tochi\/)/)) {
    const target = d.querySelector('.re-list')
    const paginationElement = d.querySelector('.pagination')
    if (target && paginationElement) {
      const pagination = new Pagination({
        target: target,
        body: paginationElement,
        prev: paginationElement.querySelector(".prev"),
        next: paginationElement.querySelector(".next"),
        shortenAfter: paginationElement.querySelector(".shorten"),
        shortenBefore: paginationElement.querySelector(".shorten").cloneNode(true),
        itemPerPage: 12,
      })
    }
    return;
  } else if (location.pathname.match(/^\/realestate\/flyer\/|^\/realestate\/faq\//)) {
    const target = d.querySelector('.article-list')
    const paginationElement = d.querySelector('.pagination')
    if (target && paginationElement) {
      const pagination = new Pagination({
        target: target,
        body: paginationElement,
        prev: paginationElement.querySelector(".prev"),
        next: paginationElement.querySelector(".next"),
        shortenAfter: paginationElement.querySelector(".shorten"),
        shortenBefore: paginationElement.querySelector(".shorten").cloneNode(true),
        itemPerPage: 12,
      })
    }
  }

  


})(document);