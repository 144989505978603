import Swiper from 'swiper';

var mySwiper = new Swiper('.top-page .section-slider .swiper-container', {
  slidesPerView: 'auto',
  loop: true,
  centeredSlides: true,
  navigation: {
    nextEl: '.swiper-button-next', // 次のスライドボタンのセレクタ
    prevEl: '.swiper-button-prev', // 前のスライドボタンのセレクタ
  },
  breakpoints: {
    768: {
      centeredSlides: true,
    },
  },
});

var mySwiper = new Swiper('.page-new-construction .section-slider .swiper-container', {
  slidesPerView: 'auto',
  loop: true,
  // centeredSlides: true,
  navigation: {
    nextEl: '.swiper-button-next', // 次のスライドボタンのセレクタ
    prevEl: '.swiper-button-prev', // 前のスライドボタンのセレクタ
  },
  breakpoints: {
    768: {
      centeredSlides: true,
    },
  },
});

var mySwiper = new Swiper('.page-reform2 .section-slider .swiper-container', {
  slidesPerView: 'auto',
  loop: true,
  // centeredSlides: true,
  navigation: {
    nextEl: '.swiper-button-next', // 次のスライドボタンのセレクタ
    prevEl: '.swiper-button-prev', // 前のスライドボタンのセレクタ
  },
  breakpoints: {
    768: {
      centeredSlides: true,
    },
  },
});

var resultsSwiper = new Swiper('.section-results .swiper-container', {
  slidesPerView: 'auto',
  autoplay: false,
  navigation: {
    nextEl: '.swiper-button-next', // 次のスライドボタンのセレクタ
    prevEl: '.swiper-button-prev', // 前のスライドボタンのセレクタ
  },
});

var resultsSwiper = new Swiper('.section-surveys .swiper-container', {
  slidesPerView: '1',
  autoplay: false,
  navigation: {
    nextEl: '.swiper-button-next', // 次のスライドボタンのセレクタ
    prevEl: '.swiper-button-prev', // 前のスライドボタンのセレクタ
  },
  breakpoints: {
    1024: {
      slidesPerView: '3',
    },
  },
});

function updateVideo() {
  let video = undefined
  video = document.querySelector('.video')
  // if (window.innerWidth > 768) {
  // } else {
  //   video = document.querySelector('.video-sp')
  // }
  if (video) {
    video.classList.remove('show')
    video.setAttribute('src', video.getAttribute('data-src'));
    document.querySelector('.kv').classList.add('show');
    video.classList.add('show');
    var playPromise = video.play();
    if (playPromise !== undefined) {
      playPromise.then(_ => {
          console.log('play start')
          // Automatic playback started!
          // Show playing UI.
        })
        .catch(error => {
          // Auto-play was prevented
          // Show paused UI.
        });
    }
  }
}
window.addEventListener("load", function () {
  updateVideo()
})
window.addEventListener("resize", function () {
  updateVideo()
})