import Pagination from "./pagination.js";
(function (d) {
  if (!location.pathname.match(/^\/realestate\//)) {
    return;
  }

  if (
    location.pathname.match(/^(\/realestate\/tateuri\/|\/realestate\/tochi\/)/)
  ) {
    const target = d.querySelector(".re-list");
    const paginationElement = d.querySelector(".pagination");
    if (target && paginationElement) {
      const pagination = new Pagination({
        target: target,
        body: paginationElement,
        prev: paginationElement.querySelector(".prev"),
        next: paginationElement.querySelector(".next"),
        shortenAfter: paginationElement.querySelector(".shorten"),
        shortenBefore: paginationElement
          .querySelector(".shorten")
          .cloneNode(true),
        itemPerPage: 12,
      });
    }
    console.log("通ったよ");
    const stickyNav = d.querySelector(".tab-sticky");
    if (stickyNav) {
      const sections = d.querySelectorAll(".nav-section");
      const navItems = stickyNav.querySelectorAll(".nav-item a");

      const observerOptions = {
        root: null, // viewport
        threshold: 0, // 60% of the section is visible
      };
      let currentActiveSectionId = null;

      const observer = new IntersectionObserver((entries) => {
        let mostVisibleEntry = null;

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // 最も見えているセクションを選ぶ
            if (
              !mostVisibleEntry ||
              entry.intersectionRatio > mostVisibleEntry.intersectionRatio
            ) {
              mostVisibleEntry = entry;
            }
          }
        });

        if (mostVisibleEntry) {
          const newActiveId = mostVisibleEntry.target.id;

          // Activeクラスを更新
          if (newActiveId !== currentActiveSectionId) {
            currentActiveSectionId = newActiveId;

            // 全てのNavアイテムのActiveをクリア
            navItems.forEach((nav) => nav.classList.remove("active"));

            // 新しいActiveを設定
            const activeNav = document.querySelector(
              `.nav-item a[href="#${newActiveId}"]`
            );
            if (activeNav) activeNav.classList.add("active");
          }
        }
      }, observerOptions);
      sections.forEach((section) => observer.observe(section));
    }
    return;
  } else if (
    location.pathname.match(/^\/realestate\/flyer\/|^\/realestate\/faq\//)
  ) {
    const target = d.querySelector(".article-list");
    const paginationElement = d.querySelector(".pagination");
    if (target && paginationElement) {
      const pagination = new Pagination({
        target: target,
        body: paginationElement,
        prev: paginationElement.querySelector(".prev"),
        next: paginationElement.querySelector(".next"),
        shortenAfter: paginationElement.querySelector(".shorten"),
        shortenBefore: paginationElement
          .querySelector(".shorten")
          .cloneNode(true),
        itemPerPage: 12,
      });
    }
  }
})(document);
