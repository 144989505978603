// //フォームのUI、バリデーション
const validateRules = [{
    type: 'required',
    validate: (el) => {
      let val = el.value
      let name = el.name.replace(/[ !"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&')
      if (el.type == 'checkbox' || el.type == 'radio') {
        el = document.querySelector(`[name=${name}]:checked`);
        if (!el) return false
      }
      return !(val === '' || val === null || val === undefined)
    },
    errorMessage: (label) => {
      return `${label}を入力してください`
    }
  },
  {
    type: 'number',
    validate: (el) => {
      let val = el.value
      if (val === '' || val === null || val === undefined) return true
      return val.toString().match(/^[0-9０-９].+$/)
    },
    errorMessage: (label) => {
      return `${label}は数字を入力してください`
    }
  },
  {
    type: 'tel',
    validate: (el) => {
      let val = el.value
      if (val === '' || val === null || val === undefined) return true
      return val.toString().match(/^[0-9０-９]{10,14}$|^[0-9０-９]{3,4}(-|ー|－)[0-9０-９]{3,4}(-|ー|－)[0-9０-９]{4}$/)
    },
    errorMessage: (label) => {
      return '正しい電話番号を入力してください'
    }
  },
  {
    type: 'mail',
    validate: (el) => {
      let val = el.value
      if (val === '' || val === null || val === undefined) return true
      return val.toString().match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    },
    errorMessage: (label) => {
      return `正しいメールアドレスを入力してください`
    }
  },
  {
    type: 'zip',
    validate: (el) => {
      let file = el.value
      if (val === '' || val === null || val === undefined) return true
      return value.toString().match(/^\d{3}-?\d{4}$/)
    },
    errorMessage: (label) => {
      return `7桁の数字またはハイフンで区切られた数字を入力してください`
    }
  },
  {
    type: 'fileSize',
    validate: (el) => {
      let file = el.value
      let size = file.size;
      const KB = 1024;
      const MEGA = Math.pow(KB, 2);
      const MAX_SIZE = MEGA * 2;
      return size.toString().match(/^\d{3}-?\d{4}$/)
    },
    errorMessage: (label) => {
      return `${MAX_SIZE / MEGA}MB以下のファイルを選択してください`
    }
  },
  {
    type: 'fileExt',
    validate: (el) => {
      try {
        let file = el.value
        const filename = file.name
        const ext = filename.split('.')[1];
        return ext.match(/^[jpg|JPG|jpeg|png|gif]$/)
      } catch (e) {
        return false
      }
    }
  },
]

function ValidateForm(form) {
  this.submitBtns = form.querySelectorAll('[type=submit]');
  this.valid = true;
  this.errors = [];
  this.validate = function (el) {
    let validateEl = el;
    //type[]とかあるのでエスケープ
    const name = el.name.replace(/[ !"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&');
    
    if (el.type == 'checkbox' || el.type == 'radio') {
      validateEl = document.querySelector(`[name=${name}][data-validate]`);
    }
    if (!validateEl || !validateEl.hasAttribute('data-validate')) return;
    const errorTextEl = document.querySelector(`[data-error-for=${name}]`);
    const label = validateEl.getAttribute('data-label') ? validateEl.getAttribute('data-label') : validateEl.getAttribute('name');
    // const 
    const errors = [];
    const hasError = validateRules.some(rule => {
      if (!validateEl.hasAttribute('data-' + rule.type)) return false;
      if(validateEl.hasAttribute('disabled')) return false;
      if (!rule.validate(el)) {
        //バリデーションエラー
        let errMessage = ''
        if (errorTextEl) {
          errMessage = validateEl.getAttribute('data-error-text') ? validateEl.getAttribute('data-error-text') : rule.errorMessage(label);
          errorTextEl.innerHTML = errMessage;
        }
        errors.push({
          id: validateEl.id,
          message: errMessage
        })
        return true
      }
      return false
    })
    //エラーがあればもろもろ操作
    if (hasError) {
      
      //DOM
      if (el.type == 'checkbox' || el.type == 'radio') {
        document.querySelectorAll(`[name=${name}]`).forEach(v => {
          v.classList.add('error');
        });
      } else {
        el.classList.add('error');
      }
      //radio.checkbox


      //フォーム全体に通知
      this.setErrors(errors[0], 'add')
      // this.errors.push(errors[0])
      return true
    } else {
      //なければクラスなどを削除
      if (el.type == 'checkbox' || el.type == 'radio') {
        document.querySelectorAll(`[name=${name}]`).forEach(v => {
          v.classList.remove('error');
        });
      } else {
        el.classList.remove('error');
      }
      if (errorTextEl) errorTextEl.innerHTML = '';
      this.setErrors({
        id: el.id
      }, 'remove');
      // this.errors = this.errors.filter(v => {
      //   return v.id != el.id
      // })
      return false;
    }
  }
  this.validateAll = function (e) {
    Array.from(form.elements).forEach(el => {
      this.validate(el)
    })
    return this.errors.length == 0;
  }
  this.setErrors = function (err, type) {
    const id = err.id
    if (type == 'add') {
      if (!this.errors.some(v => {
          return v.id == id
        })) {
        this.errors.push(err)
      }
    } else {
      this.errors = this.errors.filter(v => {
        return v.id != id
      })
    }
    // if(this.errors.length > 0) {
    //   this.submitBtns.forEach(btn => {
    //     btn.setAttribute('disabled','')
    //   })
    // } else {
    //   this.submitBtns.forEach(btn => {
    //     btn.removeAttribute('disabled')
    //   })
    // }
  }

  return this
}

export default ValidateForm;