import Pagination from './pagination.js';
(function (d) {
  if (!location.pathname.match(/^\/about\/movie\//)) {
    return;
  }
  const target = d.querySelector('.movie-list')
  const paginationElement = d.querySelector('.pagination')
  if (target && paginationElement) {
    const pagination = new Pagination({
      target: target,
      body: paginationElement,
      prev: paginationElement.querySelector(".prev"),
      next: paginationElement.querySelector(".next"),
      shortenAfter: paginationElement.querySelector(".shorten"),
      shortenBefore: paginationElement.querySelector(".shorten").cloneNode(true),
      itemPerPage: 8,
    })
  }
  return;


})(document);