//ウェブフォント読み込み
window.WebFontConfig = {
  google: {
    families: ['Oswald:Light,Regular']
  },
  active: function () {
    sessionStorage.fonts = true;
  }
};
(function (d) {
  var wf = d.createElement('script'),
    s = d.scripts[0];
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
  wf.async = true;
  s.parentNode.insertBefore(wf, s);
})(document);

//IE11 polyfill
if ('NodeList' in window && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
//空のpタグを削除
(function (d) {
  const paragraphs = d.querySelectorAll('article p').forEach(p=> {
    if(p.innerHTML == '<br>') p.remove();
  })
})(document);