import ValidateForm from "./form.js";
(function (d) {
  window.addEventListener("load", function () {
    if (typeof C3TEForm === "undefined") return;
    const formElement = d.querySelector("#form");

    let validateForm = null;
    if (formElement) {
      validateForm = new ValidateForm(formElement);
    }

    const form = C3TEForm.get("form");
    formElement.addEventListener("change", (e) => {
      validateForm.validate(e.target);
    });
    formElement.addEventListener("input", (e) => {
      if (e.target.classList.contains("error")) {
        validateForm.validate(e.target);
      }
    });
    form.onOptimize = function (data) {
      if (!data.zip && data.zip1 && data.zip2) {
        data.zip = data.zip1 + "-" + data.zip2;
      }
      return data;
    };
    form.onValidate = function (data) {
      validateForm.errors = [];
      if (!validateForm.validateAll()) {
        d.querySelector(".error-title").textContent =
          "入力内容に誤りがあります。";
        const rect = formElement.getBoundingClientRect();
        var position = rect.top + window.pageYOffset - 90;
        window.scrollTo({
          top: position,
          behavior: "smooth",
        });
      }
      //メッセージにリンク貼り付けることは可能か？
      return validateForm.errors.map((v) => {
        // let anchor = document.createElement('a')
        // anchor.setAttribute('href', '#' + v.id);
        // anchor.innerText = v.message
        // return anchor
        return v.message;
      });
    };
    form.onComplete = function () {
      if (
        d.querySelector('input[name="type"]:checked') &&
        d.querySelector('input[name="type"]:checked').value !== "その他"
      ) {
        gtag("event", "Submit", {
          event_category: "Form",
          event_label: "contact",
        });
      } else {
        gtag("event", "Submit-other", {
          event_category: "Form",
          event_label: "contact",
        });
      }

      setTimeout(function () {
        const rect = document
          .querySelector("#form-completed")
          .getBoundingClientRect();
        var position = rect.top + window.pageYOffset - 120;
        window.scrollTo({
          top: position,
          behavior: "smooth",
        });
      }, 200);
    };
    form.onError = function () {
      setTimeout(function () {
        const rect = formElement.getBoundingClientRect();
        var position = rect.top + window.pageYOffset - 120;
        window.scrollTo({
          top: position,
          behavior: "smooth",
        });
      }, 200);
    };
    d.querySelectorAll(".btn-postcode").forEach((btn) => {
      btn.addEventListener("click", (e) => {
        d.querySelector("#zip-error").innerText = "";
        const value = d.querySelector("#address").value;
        if (value && value.length > 0) {
          const target = d.querySelector(btn.getAttribute("data-target"));
          target.focus();
          target.value = value;
          validateForm.validate(target);
        } else {
          d.querySelector("#zip-error").innerText =
            "郵便番号が取得できませんでした";
        }
      });
    });
    const zip2 = d.querySelector("[name=zip2]");
    if (zip2) {
      zip2.addEventListener("change", function (e) {
        d.querySelector("#zip-error").innerText = "";
      });
    }
    const date_none = d.querySelector("[name=preferred_dates_none]");
    const preferred_time = d.querySelectorAll(".preferred_time");
    date_none &&
      date_none.addEventListener("change", function (e) {
        const target = d.querySelector("#form-preferred_dates");
        const target_time = d.querySelector("#form-preferred_times");
        if (e.target.checked) {
          target.setAttribute("disabled", "disabled");
          target.value = "";
          target_time.setAttribute("disabled", "disabled");
          target_time.value = "";
          preferred_time.forEach((el) => (el.style.display = "none"));
        } else {
          target.removeAttribute("disabled");
          target_time.removeAttribute("disabled");
          preferred_time.forEach((el) => (el.style.display = "block"));
        }
      });
    var formEl = d.querySelector("#form");
    if (formEl) {
      document.cookie.split("; ").map((kv) => {
        var element = kv.split("=");
        var paramName = element[0];
        var paramValue = element[1];
        if(paramName.includes("utm")) {
          var input = d.createElement("input");
          input.setAttribute("type", "hidden");
          input.setAttribute("name", paramName);
          input.setAttribute("value", paramValue);
          formEl.appendChild(input);
        }
      });
    }
  });
})(document);
