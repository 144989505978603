import "spotlight.js";
import "lazysizes";
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import "scroll-behavior-polyfill";
import sliders from "./slider.js";
import flatpickr from "flatpickr";
import {
  Japanese
} from "flatpickr/dist/l10n/ja.js";

(function (d) {
  const breadkPoints = {
    sm: 350,
    md: 768,
    lg: 1200,
    xl: 1400
  }
  //ナビゲーション
  const body = d.querySelector('body');
  const header = d.querySelector('header');

  // メニューの展開
  const navFunctions = function () {
    let target = d.querySelector('#mega-menu');
    let megaGlobal = d.querySelector('.mega-menu-global');
    let megaLocals = d.querySelectorAll('.mega-menu-local');
    let subNavToggle = d.querySelectorAll('.sub-nav-toggle');
    d.querySelectorAll('.navbar-toggler').forEach(v => {
      v.addEventListener('mouseover', e => {
        if (window.innerWidth > breadkPoints.md) {
          v.classList.add('opened');
          target.classList.add('show')
          header.classList.add('show')
          megaGlobal.classList.add('show')
          megaLocals.forEach(v => {
            v.classList.remove('show')
          })
          d.querySelectorAll('.main-menu li').forEach(c => {
            c.classList.remove('active');
          })
        }
      })
      v.addEventListener('click', e => {
        if (window.innerWidth <= breadkPoints.md) {
          target.classList.toggle('show')
          v.classList.toggle('opened');
          header.classList.toggle('show')
          megaGlobal.classList.add('show')
          megaLocals.forEach(v => {
            v.classList.remove('show')
          })
        }

      })
    })
    // 新築などの個別メニュー
    d.querySelectorAll('.main-menu li').forEach(v => {
      v.addEventListener('mouseover', e => {
        e.preventDefault();
        for (let index = 0; index < v.parentNode.children.length; index++) {
          const element = v.parentNode.children[index];
          element.classList.remove('active');
        }
        d.querySelector('.navbar-toggler').classList.remove('opened');
        v.classList.add('active');
        let localType = v.getAttribute('data-target');
        target.classList.add('show')
        header.classList.add('show')
        megaGlobal.classList.remove('show')
        megaLocals.forEach(v => {
          v.classList.remove('show')
          if (v.getAttribute('name') === localType) {
            v.classList.add('show')
          }
        })
      })
    })
    //headerからマウスアウトでメニューを閉じる
    d.querySelector('header').addEventListener('mouseleave', e => {
      d.querySelectorAll('.main-menu li').forEach(v => {
        v.classList.remove('active');
      })
      target.classList.remove('show');
      header.classList.remove('show');
    })
    //サブナビのトグルメニュー
    subNavToggle.forEach(v => {
      v.addEventListener('click', e => {
        v.classList.toggle('opened');
        const sibling = v.nextElementSibling;
        sibling.classList.toggle('active');
      })
    })

  }
  navFunctions();

  //
  //スクロールの閾値
  let onTopHeight = 370;
  let ScrolledHeight = 370;

  const ctaSp = d.querySelector('.cta-sp');
  const main = d.querySelector('main');


  window.onscroll = function (e) {
    let scrollTop = d.documentElement.scrollTop || d.body.scrollTop;
    // const ih = window.innerHeight;
    //スクロールでのの大小のコントロール
    if (breadkPoints)
      if (scrollTop < onTopHeight) {
        header.classList.remove('scrolled');
      } else {
        header.classList.add('scrolled');
      }
    //透明と不透明
    //3階層目なら何もしない
    if (scrollTop < onTopHeight) {
      header.classList.add('ontop');
    } else {
      header.classList.remove('ontop');
    }
    // console.log(scrollTop)
    // if(scrollTop >= (ih / 2)) {
    //   ctaSp.classList.add('show');
    // }
  }
  //CTAの挙動
  const ctaFunction = function () {
    const cta = d.querySelector('.cta')
    const toggler = d.querySelectorAll('.cta-toggler')
    toggler.forEach(v => {
      v.addEventListener('click', (e) => {
        cta.classList.toggle('closed')
        cta.classList.toggle('show')
      })
    })
    const closeBtn = d.querySelector('.cta-close');
    if(closeBtn) {
      closeBtn.addEventListener('click', () =>  {
        const ctaSp = d.querySelector('.cta-sp')
        ctaSp.classList.remove('show');
      })
    }
    
    // 新築などの個別メニュー
    const ctaNavs = d.querySelectorAll('.cta-nav li a');
    const ctaNavLocals = d.querySelectorAll('.cta-nav-local')
    ctaNavs.forEach(v => {
      v.addEventListener('mouseover', e => {
        e.preventDefault();
        ctaNavs.forEach(v => v.classList.remove('active'));
        v.classList.add('active');
        let localType = v.getAttribute('data-target');
        ctaNavLocals.forEach(v => {
          v.classList.remove('show')
          if (v.getAttribute('name') === localType) {
            v.classList.add('show')
            v.classList.add('active')
          }
        })
      })
    })
    //問い合わせページとイベントページでは消す
    const path = location.pathname
    if(path.includes('/contact/') || path.includes('/topics/')) {
      d.querySelector('footer .cta').remove()
    }
  }
  ctaFunction();

  //内部リンク
  const pageAnchorFunction = function () {
    d.querySelectorAll('a[href^="#"]').forEach(v => {
      v.addEventListener('click', scrollToAnchor);
    })
  };

  function scrollToAnchor(e) {
    e.preventDefault();
    const speed = 500;
    const target = d.querySelector(e.currentTarget.getAttribute('href'));
    const rect = target.getBoundingClientRect();
    var position = rect.top + window.pageYOffset - 90;
    window.scrollTo({
      top: position,
      behavior: "smooth"
    })
  }
  pageAnchorFunction();


  //ギャラリー画像ビューワー
  const galleries = d.querySelectorAll('.image-gallery');

  //タブ
  const tabChangeFunction = function () {
    const tabWrapper = d.querySelectorAll('.tab-singlepage');
    tabWrapper.forEach(wrapper => {
      const navs = wrapper.querySelectorAll('.tab-nav .nav-item');
      // const links = wrapper.querySelectorAll('.tab-nav .nav-item');
      const contents = wrapper.querySelectorAll('.tab-content')
      navs.forEach(nav => {
        const anchor = nav.querySelector('a');
        anchor.removeEventListener('click', scrollToAnchor);
        anchor.addEventListener('click', e => {
          e.preventDefault();
          const href = anchor.getAttribute('href');
          if (href.indexOf("#") < 0) return;
          e.preventDefault();
          const target = d.querySelector(href);
          navs.forEach(c => {
            c.querySelector('a').classList.remove('active');
          })
          contents.forEach(c => {
            c.classList.remove('active');
          })
          anchor.classList.add('active');
          target.classList.add('active');
        })
      })
    })
  }
  tabChangeFunction();

  const sideNavControl = function () {
    const sideNav = d.querySelector('.side-nav');
    if (sideNav) {
      const pathname = location.pathname;
      const dir = pathname.substring(0, pathname.lastIndexOf('/')) + '/';
      sideNav.querySelectorAll('.nav-link').forEach(v => {
        const href = v.getAttribute('href');
        if (dir === href) {
          v.classList.add('active');
        }
      })
    }
  }
  sideNavControl()

  //デートピッカー
  flatpickr('.datepicker', {
    locale: Japanese,
    dateFormat: "Y年m月d日",
  })

})(document);